import React, { useCallback } from "react";
import DadosBasicosInterForm from "./DadosBasicosInterForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isBuscandoPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators from "../../../../utils/validators";
import { actions } from "../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../utils/basic";
import formatters from "../../../../utils/formatters";

const DadosBasicosInterFormConnected = ({
  handleGoTo2,
  events,
  setComprador,
  comprador,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { genero: generos, escolaridade: escolaridades },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);
  const searching = useSelector(isBuscandoPreenchimentoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setComprador({
          ...c,
          fluxo: data.validacao[
            data.operacao.detalhe.correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        });
      }
      handleGoTo2();
    },
    [handleGoTo2, setComprador, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cpfCnpj({
        required: false,
        custom: {
          obrigatorio: (value) => {
            const c = operacao.participantes.find((p) => p.id === comprador.id);
            if (!!c.cpfCnpj) return true;
            if (value !== undefined && value !== null && value !== "") {
              return true;
            }
            return "Campo obrigatório";
          },
        },
      }),
      nome: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),
      dataNascimento: validators.date({
        required: true,
        min: "01/01/1900",
        max: formatters.dates.short(new Date()),
      }),
      email: validators.email({ required: true }),
      genero: validators.object({ required: true }),
      escolaridade: validators.object({ required: true }),
      naturalidade: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          validTextSlashText: (value) =>
            !value ||
            /^[A-Za-zÀ-ÖØ-öø-ÿ]+ \/ [A-Za-zÀ-ÖØ-öø-ÿ]+$/.test(value) ||
            'É necessário inserir a cidade e UF no formato: "Cidade de Nascimento / UF".',
        },
      }),
      telefone: validators.phone({ required: true }),
      ppe: validators.object({ required: true }),
      usPerson: validators.object({ required: true }),
      residenciaFiscalBrasil: validators.object({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        cpfCnpj: "",
        dataNascimento: "",
        nome: "",
        escolaridade: null,
        genero: null,
        email: "",
        telefone: "",
        ppe: null,
        usPerson: null,
        residenciaFiscalBrasil: null,
        naturalidade: "",
        ...comprador,
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-pessoais",
            participante: {
              id: comprador?.id,
              cpfCnpj: removeNonDigitsFromString(values.cpfCnpj),
              dataNascimento: transformToDate(values.dataNascimento),
              nomeParticipante: values.nome,
              idEscolaridade: values.escolaridade?.id,
              idGenero: values.genero?.id,
              email: values.email,
              telefone: values.telefone,
              ppe: values.ppe,
              usPerson: values.usPerson,
              residenciaFiscalBrasil: values.residenciaFiscalBrasil,
              naturalidade: values.naturalidade,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback]
    ),
  });

  const { reset } = formProps;

  const successCallback = useCallback(
    (data) => {
      if (!!data) {
        const c = data.operacao.participantes.find(
          (p) => p.id === comprador.id
        );
        if (!!c) {
          setComprador({
            ...c,
            fluxo: data.validacao[
              data.operacao.detalhe.correntista ? "correntista" : "completa"
            ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
          });
          reset({
            cpfCnpj: "",
            nome: "",
            dataNascimento: "",
            escolaridade: null,
            genero: null,
            email: "",
            telefone: "",
            ppe: null,
            usPerson: null,
            residenciaFiscalBrasil: null,
            naturalidade: "",
            ...c,
          });
        }
      }
    },
    [setComprador, comprador, reset]
  );

  const handleCpfCnpjChange = useCallback(
    (v) => {
      if (!!v) {
        dispatch(
          actions.atualizarParticipantePreenchimento.request({
            id: comprador.id,
            cpfCnpj: removeNonDigitsFromString(v),
            tipo: "preenchimento",
            callback: successCallback,
          })
        );
      }
    },
    [dispatch, comprador, successCallback]
  );

  return (
    <DadosBasicosInterForm
      {...props}
      tipoPessoa={operacao.detalhe.tipoPessoa}
      escolaridades={escolaridades}
      generos={generos}
      formProps={formProps}
      handleSubmit={handleSubmit}
      handleCpfCnpjChange={handleCpfCnpjChange}
      searching={searching}
      cpfCnpjDisabled={!!comprador.cpfCnpj}
      submitting={submitting}
      fluxo={comprador.fluxo}
      events={events}
    />
  );
};

export default DadosBasicosInterFormConnected;
