import React, { useCallback, useEffect } from "react";
import DadosBasicosCompradorBradescoForm from "./DadosBasicosCompradorBradescoForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../../utils/basic";
import formatters from "../../../../../utils/formatters";

const DadosBasicosCompradorBradescoFormConnected = ({
  handleGoTo2,
  events,
  participante: comprador,
  setParticipante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: {
      nacionalidade: nacionalidades,
      genero: generos,
      documentoPessoal: documentosPessoais,
    },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      handleGoTo2();
    },
    [handleGoTo2, setParticipante, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cpfCnpj({ required: true }),
      nome: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),
      dataNascimento: validators.date({
        required: true,
        min: "01/01/1900",
        max: formatters.dates.short(new Date()),
      }),
      nacionalidade: validators.object({ required: true }),
      email: validators.email({ required: true }),
      nomeMae: validators.string({ required: true, maxLength: 100 }),
      genero: validators.object({ required: true }),
      documentoPessoal: validators.object({ required: true }),
      numeroDocumentoPessoal: validators.string({
        required: true,
        maxLength: 100,
      }),
      orgaoDocumentoPessoal: validators.string({
        required: true,
        maxLength: 100,
      }),
      ufOrgaoDocumentoPessoal: validators.string({
        required: true,
      }),
      dataExpedicaoDocumentoPessoal: validators.date({
        required: true,
        min: "01/01/1900",
        max: formatters.dates.short(new Date()),
      }),
      naturalidade: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          validTextSlashText: (value) =>
            !value ||
            /^[A-Za-zÀ-ÖØ-öø-ÿ]+ \/ [A-Za-zÀ-ÖØ-öø-ÿ]+$/.test(value) ||
            'É necessário inserir a cidade e UF no formato: "Cidade de Nascimento / UF".',
        },
      }),
      nomeProcurador: validators.string({ required: true }),
      cpfProcurador: validators.cpf({ required: true }),
      telefone: validators.phone({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        possuiProcuracao: false,
        cpfCnpj: "",
        nome: "",
        dataNascimento: "",
        nomeMae: "",
        telefoneResidencial: "",
        nacionalidade: null,
        genero: null,
        documentoPessoal: null,
        email: "",
        telefone: "",
        numeroDocumentoPessoal: "",
        orgaoDocumentoPessoal: "",
        ufOrgaoDocumentoPessoal: "",
        dataExpedicaoDocumentoPessoal: "",
        naturalidade: "",
        nomeProcurador: "",
        cpfProcurador: "",
        possuiCnh: comprador.possuiCnh ?? comprador.documentoPessoal.descricao == "CNH" ? true : false,
        ...comprador,
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-pessoais",
            participante: {
              id: comprador?.id,
              possuiProcuracao: values.possuiProcuracao,
              nomeParticipante: values.nome,
              dataNascimento: transformToDate(values.dataNascimento),
              nomeMae: values.nomeMae,
              telefoneResidencial: values.telefoneResidencial,
              idNacionalidade: values.nacionalidade?.id,
              idGenero: values.genero?.id,
              idDocumentoPessoal: values.documentoPessoal?.id,
              numeroDocumentoPessoal: values.numeroDocumentoPessoal,
              orgaoDocumentoPessoal: values.orgaoDocumentoPessoal,
              ufOrgaoDocumentoPessoal: values.documentoPessoal?.descricao === "RNE" ? null : values.ufOrgaoDocumentoPessoal,
              possuiCnh: documentosPessoais.find(d => d.id == values.documentoPessoal.id).descricao != "CNH" ? values.possuiCnh : true,
              dataExpedicaoDocumentoPessoal: transformToDate(
                values.dataExpedicaoDocumentoPessoal
              ),
              email: values.email,
              telefone: values.telefone,
              naturalidade: values.naturalidade,
              nomeProcurador: values.possuiProcuracao
                ? values.nomeProcurador
                : null,
              cpfProcurador: values.possuiProcuracao
                ? removeNonDigitsFromString(values.cpfProcurador)
                : null,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      possuiProcuracao: false,
      cpfCnpj: "",
      nome: "",
      dataNascimento: "",
      nacionalidade: null,
      naturalidade: "",
      genero: null,
      documentoPessoal: null,
      email: "",
      telefone: "",
      nomeMae: "",
      telefoneResidencial: "",
      numeroDocumentoPessoal: "",
      orgaoDocumentoPessoal: "",
      ufOrgaoDocumentoPessoal: "",
      dataExpedicaoDocumentoPessoal: "",
      nomeProcurador: "",
      cpfProcurador: "",
      ...comprador,
    });
  }, [comprador, reset]);

  return (
    <DadosBasicosCompradorBradescoForm
      {...otherProps}
      tipoPessoa={operacao.detalhe.tipoPessoa}
      nacionalidades={nacionalidades}
      documentosPessoais={documentosPessoais}
      generos={generos}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={comprador.fluxo}
      events={events}
    />
  );
};

export default DadosBasicosCompradorBradescoFormConnected;
